import Hero from '@/sections/home/components/Hero';
import NewsLetter from '@/sections/home/components/NewsLetter';
import React from 'react';
import { CryptoChart } from '@/sections/home/components/CryptoChart';
import { HomeSlider } from '@/sections/home/components/HomeSlider';
import { AuctionSection } from '@/sections/marketplace/AuctionSection';

export const HomeSection = () => {
    return (
        <>
            <Hero />
            <div className="text-center my-1 mb-24">
                <a
                    href="https://icoholder.com/en/a-nft-world-1071376"
                    target="_blank"
                    rel="noreferrer"
                    className="px-6 py-4 text-lg lg:text-2xl border rounded-lg dark:text-accent hover:bg-accent dark:hover:text-white ml-2"
                >
                    ICO Holder Listing
                </a>
            </div>
            <div className="text-center my-1 mb-24">
                <a
                    href="https://artechfusion.gallery/"
                    target="_blank"
                    rel="noreferrer"
                    className="px-6 py-4 text-lg lg:text-2xl border rounded-lg dark:text-accent hover:bg-accent dark:hover:text-white ml-2"
                >
                    Visit Our Gallery
                </a>
            </div>
            {/*<HomeSlider /> Dragos Note : eliminating this slider till we will update it with new artwork */}
            {/* <CryptoChart /> */}
            <h2 className="dark:text-white text-4xl font-bold text-center mt-[10rem]">Items on sale</h2>
            <AuctionSection limit={10} />

            <NewsLetter />
        </>
    );
};
